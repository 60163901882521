@import '../../../constants/Colors.module.scss';

.container {
  position: fixed;
  top: 60px;
  right: 86px;
  background-color: $white;
  z-index: 999;
  border-radius: 12px;
  width: 300px;
  min-height: calc(68px - 14px - 12px);
  display: flex;
  padding: 14px calc(20px + 12px + 5px) 12px 16px;
  gap: 9px;
  transition: .2s;
  opacity: 1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25), 0px 8px 24px rgba(0, 0, 0, 0.1);

  &.hidden {
    opacity: 0;
    z-index: -1;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      color: $dark_blue_one;
    }

    .text {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: $blue_grey;
    }
  }

  .closeIcon {
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
  }
}