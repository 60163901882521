@import '../../../constants/Colors.module.scss';

.inputContainer {
  position: relative;
  width: fit-content;

  .iconContainer {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0;
    font-size: 15px;
    line-height: 22px;
    background: $dark_blue_three;
    border-radius: 12px;
    padding: 12px .6rem 12px 3rem;
    color: $white;
    width: 100%;
    font-family: Galano;

    &::placeholder {
      color: $dark_blue_one;
    }

    &:disabled {
      background-color: #E2E0EB !important;
      color: #B5B1CB !important;
      border-color: #B5B1CB !important;

      &::placeholder {
        color: #B5B1CB !important;
      }
    }
  }

  .lengthErr {
    position: absolute;
    top: 48px;
    left: 12px;
    color: red;
  }

  .submitBtn {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    &.disabled {
      span {
        background: #B5B1CB !important;

        &:hover {
          cursor: default;
        }
      }
    }

    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $white;
      letter-spacing: 1px;
      text-transform: uppercase;
      border-radius: 10px;
      padding: 8px 12px;
      background: $pink_gradient;

      &:hover {
        cursor: pointer;
      }
    }
  }
}