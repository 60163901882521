@import '../../../../constants/Colors.module.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 4rem;
  padding: 0 48px 0 28px;
  &:hover {
    cursor: pointer;
    background: $dark_blue_one;
  }
  &.selected {
    background: $dark_blue_one;
  }
  .email {
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    padding-right: 1rem;
  }
}

.chevronContainer {
    position: absolute;
    right: 28px;
    padding: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: -webkit-fill-available;
}

.chevron {
    width: .75rem;
}

.chevron.selected {
    filter: grayscale(100%);
}