@import '../../../../constants/Colors.module.scss';

.container {
  display: flex;
  height: 100%;
  width: calc(100% - 64px);
  margin: 16px 32px;
  border: 1px solid $border_color;
  border-radius: 8px;

  .plotContainer {
    flex: 1;
    height: 100%;
  }

  .signalSelect {
    height: 100%;
    padding: .5em;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 8px 4%;
    width: 250px;
    align-items: center;
    font-size: 0.8em;

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 8px;
      background: $white;
      border: 2px solid $border_color;
      border-radius: 8px;
      color: $blue_grey;
      line-height: 13px;
      font-size: 13px;


      &.notSselected {
        opacity: 0.4;
      }

      .dot {
        width: 12px;
        height: 12px;
        border-radius: 100px;
        margin-right: 8px;
        color: $white;
        font-size: 9px;
      }
    }
  }

  .closeIcon {
    margin: 11px;
    cursor: pointer;
  }
}