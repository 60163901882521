@import '../../../../../constants/Colors.module.scss';

.container {
  position: relative;
  padding-bottom: 10vh;
}

.tableContainer {
  height: calc(78vh - 36px);
  overflow: scroll;
}

.tableContainer thead th {
  position: sticky;
  top: 0;
  padding-left: 36px;
  padding-bottom: 19px;
  font-size: 14px;
  font-weight: 400;
  color: $dark_blue_two;
  text-align: left;
  text-transform: uppercase;
  background-color: $extra_light_blue_background;
  :hover {
      cursor: pointer;
  }
  span:first-child {
    margin-right: 5px;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1px;
}

.table th, .table td {
  padding: 28px 36px;
  border-bottom: 1px solid $light_blue_second;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: $blue_grey;
}

.table tr:hover {
  background-color: $light_blue_second;
}

.noDataContainer {
  text-align: center;
  width: 100%;
  position: absolute;
  margin-top: 1rem;
}