@import '../../../../../constants/Colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 22.5vw;

  h5 {
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: $dark_blue_two;
    margin: 0;
  }

  input {
    width: 100%;
    max-width: 20vw;
    background: $white;
    border: 1px solid $border_color;
    border-radius: 8px;
    padding: 8px 16px;
    color: $blue_grey;
    font-family: galano;
  }
}

.clear,
.apply {
  border-radius: 68px;
  margin-left: 20px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 14px 26px;
  font-size: 12px;
}

.clear {
  background: $white;
  color: $brand_pink;
  border: 1px solid $brand_pink !important;
  padding: 14px;
}