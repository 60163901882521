// Theme colors - Primary
$white: #ffffff;
$black: #000000;
$extra_light_blue: #F7F7FC;
$extra_light_blue_background: #F6F5FB;
$light_blue: #C0BAEE;
$light_blue_second: #E7E4F5;
$light_blue_third: #EBE9F8;
$light_blue_four: #ECEBF9;
$blue_grey: #7A71A6;
$blue_grey_second: #B5B1CB;
$dark_blue_one: #3D366E;
$dark_blue_two: #27214C;
$dark_blue_three: #140F37;
$border_color: #E1DFFF;


// Theme colors - Accent
$brand_pink: #1FDCB3;
$light_pink: #EEEDFF;
$pink_gradient: linear-gradient(135deg, $brand_pink 0%, $brand_pink 100%);
$light_card_gradient: linear-gradient(144.84deg, #FAFAFE -6.61%, #898EDC 100.1%);

// Charts
$info: #2f7ed8;
$debug: #CECECD;
$warn: #f28f43;
$error: #910000;

// Sleep Phases colors
$wake: #FFB0CD;
$REM_sleep: #4DBADA;
$light_sleep: #777EFF;
$deep_sleep: #4F3DC8;

// Badge colors
$purple_badge: #D8D5F1;

// Gradients
$green_gradient: linear-gradient(180deg, #44FFD2 0%, #0A8891 100%);
$yellow_gradient: linear-gradient(180deg, #FDFF91 0%, #B4B732 100%);
$orange_gradient: linear-gradient(180deg, #FFB084 0%, #FF6C4B 100%);
$red_gradient: linear-gradient(180deg, #FF6D6D 0%, #D60A0A 100%);
$grey_gradient: linear-gradient(135deg, #A39FB4 0%, #4D476B 100%);
$blue_gradient: linear-gradient(136.22deg, #2C5CFF 16.67%, #1F43BD 81.97%);

// Status colors - Solids
$green: #3DF0CA;
$orange: #FFA177;
$red: #FF5151;
$pink_button_disabled: #AE7E92;
$button_disabled: #E2E0EB;
$content_disabled: #B5B1CB;

:export {
  white: $white;
  black: $black;
  extra_light_blue: $extra_light_blue;
  extra_light_blue_background: $extra_light_blue_background;
  light_blue: $light_blue;
  light_blue_second: $light_blue_second;
  light_blue_third: $light_blue_third;
  light_blue_four: $light_blue_four;
  blue_grey: $blue_grey;
  blue_grey_second: $blue_grey_second;
  dark_blue_one: $dark_blue_one;
  dark_blue_two: $dark_blue_two;
  dark_blue_three: $dark_blue_three;
  border_color: $border_color;
  brand_pink: $brand_pink;
  light_pink: $light_pink;
  pink_gradient: $pink_gradient;
  light_card_gradient: $light_card_gradient;
  wake: $wake;
  REM_sleep: $REM_sleep;
  light_sleep: $light_sleep;
  deep_sleep: $deep_sleep;
  purple_badge: $purple_badge;
  green_gradient: $green_gradient;
  yellow_gradient: $yellow_gradient;
  orange_gradient: $orange_gradient;
  red_gradient: $red_gradient;
  grey_gradient: $grey_gradient;
  blue_gradient: $blue_gradient;
  info: $info;
  debug: $debug;
  warn: $warn;
  error: $error;
  green: $green;
  orange: $orange;
  red: $red;
  pink_button_disabled: $pink_button_disabled;
  button_disabled: $button_disabled;
  content_disabled: $content_disabled;
}