@import '../../../constants/Colors.module.scss';

.container {
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: $dark_blue_two;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
}

.content>* {
  margin: .25rem 0;
}