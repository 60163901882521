@import '../../../../constants/Colors.module.scss';

.wrapper {
  height: 100%;
  width: 100%;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  background-color: $white;
}

.container {
  flex: 1;
  margin: 2px 0;
  position: relative;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.featureIndicator {
  font-weight: normal;
  color: lightgray;
}

$title-bar-height: 74px;

.title {
  padding: 2rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  height: $title-bar-height;

  .breadcrumb {
    font-size: 17px;
    line-height: 25px;
    color: $blue_grey;

    >span {
      color: $light_blue;

      .chevron {
        transform: rotate(-180deg);
        margin: 0 8px;
      }
    }
  }

  .recordID {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: $dark_blue_two;
  }
}

.labelTitle {
  composes: title;
  justify-content: space-between;

  &>div:last-child {
    font-size: 0.8rem;
  }
}

.plot {

  &>div {
    width: calc(100% - 2rem);

    &:not(.title) {
      height: 100%;

      &:not(:only-child) {
        height: calc(100% - #{$title-bar-height});
      }
    }
  }
}

.singleplot {
  composes: plot;
  margin: 5px 0;
  height: 40%;
}

.multiplot {
  composes: plot;
  height: 100%;
}

.staging {
  display: flex;
  align-items: center;
  height: 100%;

  &>* {
    margin: 0 1em;
  }

  &>div {
    width: 100%;
  }

  &:first-child {
    flex-grow: 1;
  }
}

.toolbar {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  height: 56px;
  width: 82vw;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

:global {
  .fullsize-container {
    width: 100%;
    height: 100%;
  }
}