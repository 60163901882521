.config {
  position: relative;
  width: 200px;
  display: inline-block;

  dt {
    float: left;
    clear: left;
    width: 100px;
    text-align: right;

    &::after {
      content: ':';
    }
  }

  dd {
    text-align: left;
    margin: 0;
    margin-left: 110px;
    width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .remove {
    position: absolute;
    left: 10px;
    top: 0px;
    z-index: 1;
    cursor: pointer;
  }
}