@import '../../../constants/Colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: calc(2.5rem + 17px);
}

.title {
  font-size: 12px;
  margin-left: .5rem;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;

  &:hover {
    cursor: text;
  }
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-family: unset;
  outline: 0;
  box-shadow: none;
  border: 0;
  background: #fff;
  background-image: none;
  height: 2.5rem;
  line-height: 2.5;
  overflow: hidden;
  border-radius: 12px;
  padding: 0 .75rem;
  width: -webkit-fill-available;
  width: -moz-available;
  color: $blue_grey;

  &:disabled {
    background-color: #AE7E92;
  }
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: .5rem;
  transition: .25s all ease;
  color: #000;

  &:hover {
    cursor: pointer;
    color: #444;
  }
}