@import '../../../constants/Colors.module.scss';

.container {
  display: flex !important;
  align-items: center;
  gap: 20px;

  &.disabled {
    filter: grayscale(1);
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked+.slider {
        background: $pink_gradient;
      }

      &:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
        background-color: $white;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $blue_grey_second;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        top: 3px;
        left: 3px;
        background-color: $light_blue_second;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
      }
    }
  }

  .label {
    color: $blue_grey;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
  }
}