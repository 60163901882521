@import '../../../../../constants/Colors.module.scss';

.content {
  display: flex;
  flex-direction: column;

  .container {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
    margin-bottom: 1rem;
    width: 50vw;

    div {
      display: flex;
      flex-direction: column;
      width: auto;

      .item {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.button {
  border-radius: 68px !important;
  margin: 10px;
}