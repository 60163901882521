@import '../../../../constants/Colors.module.scss';

.container {
  min-width: 25vw;
  height: calc(100vh - 84px);
  background-color: $dark_blue_two;
  padding-top: 84px;
  position: relative;
  color: $white;
  overflow: scroll;
}

.newSubjectButton {
  position: absolute;
  right: 1rem;
  top: 2rem;
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid $dark_blue_one;
  border-radius: 22px;
  padding: 4px 8px;
  &.disabled {
    filter: grayscale(1);
    &:hover {
      cursor: unset;
    }
  }
  &:hover {
    cursor: pointer;
  }
  &>img {
    width: 9px;
    height: 9px;
    margin-bottom: 2px;
  }
  &>span {
    margin: 0px 0px 0px 5px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $light_blue;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}

.title {
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 36px;
  padding-left: 28px;
}

.searchBar {
  margin-bottom: 20px;
  padding: 0 28px;
  & input {
    width: -webkit-fill-available;
  }
}