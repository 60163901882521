@import '../../../../constants/Colors.module.scss';
$toolbar-width: 6rem;

.container {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.content {
  position: absolute;
  inset: 0;
}

.toolbar {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%) rotate(-90deg);
  width: $toolbar-width;
  display: flex;
  align-items: center;

  div {
    display: block;
    margin: auto;

    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $white;
      letter-spacing: 1px;
      text-transform: uppercase;
      border-radius: 10px;
      padding: 8px 12px;
      background: $pink_gradient;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.navigation {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

$app-icon-size: 120px;

.drawer {
  pointer-events: none;
  opacity: 1;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $dark_blue_three+db;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}

.drawerVisible {
  pointer-events: auto;

  &::after {
    opacity: 1;
  }

  .drawerContainer {
    transform: translateX(0);
  }

  .chevron {
    transform: rotate(90deg);
  }
}

.drawerContainer {
  position: relative;
  width: 25%;
  height: 100%;
  background-color: white;
  padding: 0 32px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.drawerContent {
  width: 100%;
  height: 100%;
  background-color: white;
  margin-left: -32px;
  padding: 0 32px;
  overflow-y: auto;
}

.toolBoxButton {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 107px;
  height: 43px;
  position: absolute;
  right: -75px;
  top: 50vh;
  color: white;
  border: none;
  background: linear-gradient(135deg, #1FDCB3 0%, #FF0064 100%);
  border-radius: 0px 0px 20px 20px;
  transform: rotate(-90deg);
  padding: 0 12px;
  pointer-events: auto;

  &:hover {
    cursor: pointer;
  }
}

.chevron {
  transform: rotate(270deg);
  transition: transform 0.3s ease-in-out;
}