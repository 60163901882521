@import "../../../constants/Colors.module.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.25rem;
  padding: 14px 8px;
  border-radius: 8px;
  color: $light_blue;
  font-size: 1.5rem !important;
}

.container:hover {
  cursor: pointer;
}

.title {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.selected {
  color: $white;
  background: $dark_blue_one;
}