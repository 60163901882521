@import '../../../../constants/Colors.module.scss';

.container {
  margin-top: 36px;
}

.header {
  margin-left: 36px;
  height: 22vh;
  & .email {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: $dark_blue_one;
    margin: 0;
  }
  & .badgeRow {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    gap: 12px;
    & .deviceBadge {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      padding: 2px 8px;
      background: $purple_badge;
      color: $dark_blue_three;
      border-radius: 100px;
    }
  }
}

.alertsColumn {
  img {
    width: 12px;
    height: 12px;
    margin-right: -4px;
  }
  span {
    margin-left: 10px;
  }
}

.fullRecordColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  img {
    height: 12px;
    margin-left: 8px;
  }
  span {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
}