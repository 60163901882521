@import '../../../../../constants/Colors.module.scss';

.brush {
  :global(.selection) {
    fill: gray;
    fill-opacity: 0.125;
  }
}

.axis {
  font-size: 0.8em;
}

.signalPlot {
  flex: 1;
  height: 100%;
}

.containerWithSignalInfo {
  display: flex;
  width: 100%;
  margin-left: 2rem;
  padding-left: 35px;
}

.signalInfo {
  height: calc(100% - 134px);
  display: flex;
  flex: 0 1;
  border-bottom: unset;
  flex-direction: column;
  color: $dark_blue_one;

  .signalInfoElement {
    height: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    font-size: 0.7rem;
    padding: .5rem;
    text-align: center;
    user-select: none;
    position: relative;
    border: 1px solid #e9e9e9;
    border-bottom: unset;

    &:last-of-type {
      border-bottom: 1px solid #e9e9e9;
    }
  }
}

.signalInfoToolbar {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    flex: 1;
    outline: none;
    font-size: 12px;
    margin: 5px;
    cursor: pointer;
    color: $blue_grey;
    border-radius: 100%;
    border: 1px solid $border_color;
    padding: 4px 8px;
    background-color: unset;
    width: 26px;
    height: 26px;

    &:hover {
      border-color: $brand_pink;
    }

    &.active {
      border-color: $brand_pink;
      border-width: 2px;
      margin: 4px;
    }
  }

  input {
    border: 1px solid $border_color;
    padding: 4px 8px;
    width: 75%;
    border-radius: 8px;
    text-align: center;
    outline-color: $brand_pink;
  }
}

.signalPlot {
  flex: 1;
  height: calc(100% - 124px);
}

.signalUnits {
  font-size: 0.55rem;
  color: $blue_grey;
}

.tooltip {
  position: absolute;
  font-size: 0.8rem;
  font-weight: 300;
  padding: 1.8em;
  background-color: whitesmoke;
  pointer-events: none;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

.labelValue {
  font-size: 1rem;
  font-weight: bold;
}

.labelPill {
  margin-left: auto;
  color: white;
  display: block;
  text-decoration: none;
  line-height: 24px;
  height: auto;
  width: 10em;
  text-align: center;
  border-radius: 16px;
}

.labelSignals {
  margin: 1em 0;
}

.labelTimestamp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;
}

.timestamp {
  color: gray;
}

.labelComment {
  text-align: justify;
  font-size: 0.7rem;
}

.labelProperties {
  text-align: center;
  font-size: 0.7rem;
}

.placeholder {
  fill: lightgray;
  fill-opacity: 0.2;
  stroke: lightgray;
  stroke-dasharray: 10 5;
}

.selected {
  fill-opacity: 0.8;
}