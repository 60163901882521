@import '../../../../constants/Colors.module.scss';

.container {
  width: 100%;

  h5 {
    margin-top: 0;
    font-size: 17px;
    line-height: 25px;
    color: $dark_blue_two;
    text-align: center;
    margin-bottom: 1em;
  }
}

.scroll {
  background-color: $extra_light_blue;
  border-radius: 20px;
  height: 45vh;
  overflow-y: auto;
  padding: 32px;
}

.element {
  font-size: 15px;
  font-weight: 400;
  color: $blue_grey;
  width: fit-content;
  cursor: move;
  padding: 8px 16px;
  background: $white;
  border: 1px solid $border_color;
  border-radius: 8px;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &>div {
    text-align: center;
  }
}