@import '../../../constants/Colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  padding: 4rem 3rem;
  color: $light_blue;
  background-color: $white;
  margin-left: 5rem;

  .logo {
    width: 8rem;
    margin: 2rem auto 3rem;
  }

  h5 {
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    color: $dark_blue_one;
    margin: 25px 0 0;

    >a {
      text-decoration: underline;
      color: $light_blue;
    }
  }

  .form {
    margin: 2rem auto;

    .input {
      margin: 2rem .5rem;
      width: 22rem;
    }
  }

  .forgotText {
    margin-bottom: .75rem;
    text-decoration: none;
    color: $dark_blue_one;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }

  .button {
    background: $pink_gradient;
    border-radius: 28.5px;
    width: 75%;
  }

  .warnText {
    color: $error;
  }
}