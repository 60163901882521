@import '../../../../constants/Colors.module.scss';

.container {
  position: relative;
  flex-grow: 1;
  overflow: auto;

  .tableContainer {
    height: 100%;

    .table {
      border-collapse: collapse;
      width: 100%;

      thead tr th {
        position: sticky;
        top: 0;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $dark_blue_two;
        text-align: left;
        padding: 16px 0;
        background: linear-gradient(to bottom,
            $extra_light_blue_background 0%,
            $extra_light_blue_background 98%,
            $light_blue_second 98%,
            $light_blue_second 100%);

        &:first-of-type {
          padding-left: 32px;
        }

        div {
          display: flex;
          gap: 4px;
          align-items: center;

          .sort {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2px;

            :hover {
              cursor: pointer;
            }

            &>svg:last-of-type {
              transform: rotate(180deg);
            }
          }
        }

      }

      .tbody {
        tr {
          border-bottom: 1px solid $light_blue_second;

          &:last-of-type {
            border-bottom: unset;
          }

          &.selected {
            border-bottom: unset;
            background-color: $light_blue_four;

            &:hover {
              background: $light_blue_four;
            }
          }

          &.unavailable {
            td {
              color: $content_disabled;
            }
          }

          &.expandableRow {
            background-color: #C0BAEE10;
            border-bottom: unset;

            &:hover {
              cursor: unset;
              background-color: #C0BAEE10;
            }
          }

          &:hover {
            cursor: pointer;
            background: $light_blue_third;
          }
        }

        td {
          padding: 20px 0;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $blue_grey;

          &:first-of-type {
            padding: 0 32px;
          }
        }
      }

      .loadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: calc(50% + 2rem);
        left: 50%;

        tr td {
          border-bottom: unset;
        }
      }
    }
  }
}

.footer {
  background-color: $extra_light_blue_background;
  border-top: 1px solid $light_blue_second;
  border-radius: 0 0 12px 12px;
  padding: 5px 0 5px 16px !important;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;

  .position {
    margin: .5rem;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $light_blue;
  }

  .pager {
    display: flex;

    .button {
      font-family: Galano;
      appearance: unset;
      background: unset;
      border: unset;
      color: $light_blue;
      margin-bottom: .25rem;
      display: flex;
      align-items: center;
      margin: 0 .5rem;

      span {
        font-size: 38px;
        margin-bottom: .25rem;
      }

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        color: #E2E0EB;
      }
    }
  }
}