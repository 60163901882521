@import '../../../constants/Colors.module.scss';

.container {
  position: relative;

  .content {
    position: absolute;
    top: 54px;
    max-height: 250px;
    overflow: scroll;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $border_color;

    .option {
      padding: 5px 16px;
      font-size: 15px;
      color: $blue_grey;
      cursor: pointer;

      &.selected {
        background-color: $extra_light_blue;
      }
    }
  }
}