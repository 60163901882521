.element {
  height: 120px;
  width: 100%;
  background: white;
  position: relative;
}

.close {
  position: absolute;
  opacity: 0.3;
  top: 5px;
  right: 10px;

  &:hover {
    opacity: 1;
  }

  &:focus,
  &:active {
    outline: none;
  }

  background: none;
  cursor: pointer;
  font-size: 14px;
  border: none;
}