@import '../../../constants/Colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  
  .header {
    padding: 68px 32px 32px;

    .searchBar {
      margin: 0 0 32px;

      img {
        color: $dark_blue_two !important;
      }

      input {
        background-color: $white;
        border: 1px solid $light_blue;
        margin: 0;
        color: $dark_blue_one;
        width: 16rem !important;

        &::placeholder {
          color: $light_blue;
        }

        &:hover:not(:disabled) {
          border-color: #1FDCB3 !important;
        }

        &:focus {
          border: 2px solid #1FDCB3 !important;
          margin: -1px;
        }
      }
    }

    h1 {
      font-weight: bold;
      font-size: 28px;
      line-height: 38px;
      color: $dark_blue_two;
      margin: 0 0 32px;
    }

    .filtersContainer {
      display: flex;
      gap: 24px;
    }
  }

  .viewSignalsBtn {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 4px 12px;
    border: 1px solid $light_blue;
    border-radius: 22px;
    width: fit-content;
    text-decoration: none;
    color: unset;

    &.disabled {
      background-color: $button_disabled;
      border-color: $content_disabled;
      color: $content_disabled;
    }
  }
}

.tooltip {
  background-color: $blue_grey !important;
  border-radius: 46px !important;
  opacity: 1 !important;

  &.uuidTooltip {
    pointer-events: auto !important;
    padding: 0 !important;

    .tooltipButton {
      background-color: transparent;
      border: none;
      border-radius: 46px;
      padding: 8px 21px;
      color: white;

      &:hover {
        cursor: pointer;
      }

      span[id^="copiedToClipboardText"],
      span[id^="clickToCopyText"] {
        display: flex;
        justify-content: center;
        color: #F7F7FC;
        font-size: 11px;
        font-weight: 300;

        &[id^="copiedToClipboardText"] {
          display: none;
        }
      }
    }
  }
}

.whiteTooltip {
  background-color: #FFFFFF !important;
  border-radius: 20px !important;
  opacity: 1 !important;
  text-transform: none;
}

.tooltip-arrow {
    display: none !important;
}

.expandableRowRender {
  display: grid;
  grid-template-columns: 33% 66%;
  gap: 1rem;
  padding: 32px 0;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: $dark_blue_one;
    margin-top: 0;
  }

  h3 {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: $dark_blue_one;
    margin-top: 0;
  }

  h4 {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: $blue_grey;
    margin-top: 0;
    margin-bottom: 0;
  }

  h5 {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: $blue_grey;
    margin-top: 0;
    margin-bottom: 0;
  }

  .qualityItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 32px;

    .qualityContainer {
      display: flex;
      gap: 4px;

      .squareQuality {
        width: 34px;
        height: 29px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        border-radius: 4px;
      }
    }
  }

  .metricsArray {
    display: flex;
    gap: 3rem;

    >div>ul {
      list-style: none;

      >li {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 6px;

        &::before {
          content: "\2022";
          color: $light_blue;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -2.25rem;
        }

        span {
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $dark_blue_one;
          margin-left: 8px;
          letter-spacing: normal;
          text-transform: none;
        }
      }
    }
  }
}