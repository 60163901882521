.select {
  display: flex;
  align-items: center;

  >button {
    margin-right: 12px;
    padding: 8px 8px 2px;
    border-radius: 100px;
  }

  > :last-child {
    flex: 1;
    margin-left: 2px;
  }
}

.profileInput {
  border: 1px solid #E1DFFF;
  border-radius: 8px;
}