.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.plotContainer {
  flex: 1;
}

.currentPosition {
  width: 12%;
  height: 100%;
  overflow: hidden;
  text-align: center;

  img {
    height: 100%;
    width: auto;
  }
}