@import '../../../constants/Colors.module.scss';

.container {
  height: calc(100vh - 6rem);
  background-color: $dark_blue_three;
  color: $white;
  padding: 4rem 14px 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.topMenu {
  margin-top: 1rem;
}

.topMenu,
.bottomMenu {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
}