@import '../../../constants/Colors.module.scss';

.container {
  width: calc(12rem);
  position: relative;

  &.disabled {
    filter: grayscale(1);
  }

  .select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: $white;
    padding: 9px 14px 9px 16px;
    border: 1px solid $border_color;
    border-radius: 8px;
    cursor: pointer;
    text-transform: capitalize;

    >div {
      display: flex;
      align-items: center;
    }

    .icon {
      width: .9rem;
      margin: 0 .5rem;
      transform: rotate(-90deg);
      transition: transform .3s;

      &.chevron {
        margin-right: 0;

        &.down {
          transform: rotate(90deg);
        }
      }

    }

    .text {
      font-weight: normal;
      font-size: 15px;
      color: $blue_grey;
    }
  }

  .listOptions {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    border: 1px solid $extra_light_blue_background;
    border-radius: 8px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.05));
    background: $white;
    z-index: 99;

    .options {
      padding: .5rem;
      cursor: pointer;
      text-transform: capitalize;

      &:hover {
        background-color: $light_pink;
      }

      &:first-of-type {
        border-radius: 8px 8px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 8px 8px;
      }
    }
  }
}