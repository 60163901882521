.title {
  font-size: 1em;
  font-weight: normal;
  text-align: center;
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputRowText {
  display: flex;
  align-items: center;
}

.inputField {
  margin: 0.4em 1em;
  width: 50%;
}