@import '../../../../../constants/Colors.module.scss';

.container {
  height: 400px;
  width: 100%;
  overflow: auto;
}

.scaleInput {
  color: $blue_grey;
  margin-bottom: 24px;

  h3 {
    margin: 0 0 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 16px;

    input,
    button {
      padding: 8px 16px;
      background: $white;
      border: 1px solid $border_color;
      border-radius: 8px;
      color: $blue_grey;
      text-align: center;

      &[type=number] {
        width: 75px;
      }

      &:enabled:hover {
        border-color: $brand_pink;
      }
    }

    button {
      cursor: pointer;
    }
  }

}

.footerButton {
  border-radius: 68px !important;
}