@import '../../../constants/Colors.module.scss';

.container {
  position: relative;

  .inputBar {
    position: relative;
    background-color: $white;
    color: $light_blue;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 13px;
    min-width: 18rem;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    border: 1px solid $light_blue;
    margin: 0;

    div {
      display: flex;
      position: absolute;
      right: 13px;
    }

    .date {
      color: $dark_blue_one;
    }

    &.focus {
      border: 2px solid #1FDCB3;
      margin: -1px;
    }

    &.disabled {
      background-color: #E2E0EB;
      color: #B5B1CB;
      border-color: #B5B1CB;

      svg {
        filter: grayscale(0.5);
      }
    }

    &:hover:not(.disabled) {
      border-color: #1FDCB3;
      cursor: pointer;
    }
  }

  .calendar {
    position: absolute;
    top: 54px;
    background-color: $white;
    z-index: 999;
    padding: 10px;
    border-radius: 12px;

    .calendarHeader {
      margin-left: 6px;

      h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: $dark_blue_one;
        margin: 0 0 2px;
      }

      span {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $blue_grey;
      }

      .navigation {
        position: absolute;
        top: 27px;
        right: 20px;
        display: flex;
        gap: 34px;

        svg:hover {
          cursor: pointer;
        }
      }
    }
  }

  .calendarDays {
    display: grid;
    grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;
    margin-top: 20px;

    >.day {
      color: $blue_grey;
      left: calc(50% - 28px/2);
      top: calc(50% - 28px/2);
      display: flex;
      justify-content: center;
      align-items: center;

      >span {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        margin: 8px auto;
        border-radius: 100px;

        &:hover {
          color: $white;
          background-image: $pink_gradient;
          cursor: pointer;
        }

        &.selected {
          color: $white;
          background-image: $pink_gradient;

          &.startDate {
            border-radius: 100px 0 0 100px;
            padding-right: 10px;
            margin-left: 10px;
          }

          &.stopDate {
            border-radius: 0 100px 100px 0;
            padding-left: 10px;
            margin-right: 10px;
          }

          &.startDate.stopDate {
            border-radius: 100px;
            padding: unset;
            margin: unset;
          }
        }

        &.inRange {
          background-color: $light_pink;
          border-radius: unset;
          width: 100%;
        }
      }
    }
  }

  .daysName {
    display: grid;
    grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;
    margin-bottom: 10px;

    >.day {
      display: flex;
      justify-content: center;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $blue_grey;
    }
  }
}