.wrapper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
}

.container {
  position: relative;
  height: 100%;
  width: 100%;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}