@import '../../../../constants/Colors.module.scss';

.toolbar {
  display: flex;
  align-items: center;
  height: 100%;
  border-top: 1px solid #e9e9e9;

  .chevron {
    border: 1px solid #E1DFFF;
    border-radius: 100%;
    padding: 6px;
  }

  svg:hover {
    cursor: pointer;
    border-color: $brand_pink;
  }

  &>* {
    margin: 0 1em;
  }

  &>div:not(.selectAlgorithm):not(.time) {
    width: 100%;
  }

  &:first-child {
    flex-grow: 1;
  }

  .selectAlgorithm {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    font-size: 12px;
  }
}

.time {
  white-space: nowrap;
  outline: none;
  user-select: none;
  width: 13.5rem;
  overflow: hidden;
  font-size: 0.8rem;
  line-height: 22px;
  color: $blue_grey;
}

.toolbarStaging {
  display: flex;
  align-items: center;
  height: 100%;

  &>div:not(.stagingSave) {
    width: calc(100% - 64px);
  }

  .stagingSave {
    width: 64px;
    text-align: center;
    margin: 0 15px;
  }
}