@import '../../../constants/Colors.module.scss';

.container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.4s;
}

.container.open {
  visibility: visible;
  opacity: 1;
}

.background {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: $dark_blue_three;
  opacity: 0.86;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: $dark_blue_two;
  padding: 32px;
  margin: 0;
  border-bottom: 1px solid $extra_light_blue_background;
}

.dialog {
  position: relative;
  z-index: 999;
  background-color: $white;
  max-height: 90vh;
  max-width: 90vw;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: -81px;
  margin-left: -12vw;

  .closeIcon {
    position: absolute;
    top: 29px;
    right: 29px;
    cursor: pointer;
  }
}

.content {
  padding: 20px 32px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 32px;
  border-top: 1px solid $extra_light_blue_background;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 60px;
  position: absolute;
  bottom: -81px;
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}