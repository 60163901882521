.container {
  height: 56px;
}

.state {
  font-size: 1.5em;
  transition: all 0.2s;
}

.current {
  fill: deeppink;
  font-size: 2em;
}