.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
}

.container {
  height: 100%;
  flex: 1;
}

.signalInfo {
  width: 100px;
  height: calc(100% - 20px);
  display: flex;
  border-right: 1px solid #e9e9e9;
  flex-direction: column;
}

.signalInfoElement {
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.signalInfoToolbar {
  position: absolute;
  left: 0;
  bottom: 1px;
  display: flex;
  align-items: center;

  button {
    outline: none;
    font-size: 9px;
    margin: 0 2px;
  }
}