@import '../../../constants/Colors.module.scss';

.container {
  position: relative;

  .inputBar {
    position: relative;
    background-color: $white;
    color: $light_blue;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 13px;
    min-width: 18rem;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    border: 1px solid $light_blue;
    margin: 0;

    div {
      display: flex;
      position: absolute;
      right: 13px;
    }

    .date {
      color: $dark_blue_one;
    }

    &.focus {
      border: 2px solid #1FDCB3;
      margin: -1px;
    }

    &.disabled {
      background-color: #E2E0EB;
      color: #B5B1CB;
      border-color: #B5B1CB;

      svg {
        filter: grayscale(0.5);
      }
    }

    &:hover:not(.disabled) {
      border-color: #1FDCB3;
      cursor: pointer;
    }
  }

  .select {
    position: absolute;
    top: 54px;
    background-color: $white;
    z-index: 999;
    padding: 16px 8px 12px 16px;
    border-radius: 12px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    height: 33.33vh;

    .column {
      height: 33.33vh;

      h3 {
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: $dark_blue_one;
        margin: 0 0 8px;
      }

      .itemList {
        overflow: auto;
        max-height: 300px;
        height: calc(33vh - 32px);

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $blue_grey;
          border-radius: 100px;
          height: 40px;
        }

        .item {
          position: relative;
          background-color: $extra_light_blue;
          color: $blue_grey;
          border-radius: 8px;
          margin: 0px 8px 8px 0;
          padding: 5px 8px;

          &:last-child {
            margin-bottom: 0;
          }

          >svg {
            position: absolute;
            height: 20px;
            right: 12px;
            top: 50%;
            transform: translate(0, -50%);
          }

          &.selected {
            background-image: $pink_gradient;
            color: #fff;
          }

          &:hover {
            cursor: pointer;
            background-color: $light_pink;
          }
        }
      }
    }
  }
}