.container {
  background: #fff;
  width: 100%;
  height: 56px;
  position: relative;
}

.canvas {
  position: absolute;
  top: 5px;
  left: 0;
  pointer-events: none;
}