@import '../../../constants/Colors.module.scss';

.container {
  position: relative;

  .inputBar {
    position: relative;
    background-color: $white;
    color: $light_blue;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 13px;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    border: 1px solid $light_blue;
    margin: 0;

    img {
      width: 15px;
    }

    span {
      color: $dark_blue_one;
      padding-right: 4rem;
    }

    &.focus {
      border: 2px solid #1FDCB3;
      margin: -1px;
    }

    &.disabled {
      background-color: #E2E0EB;
      border-color: #B5B1CB;

      span {
        color: #B5B1CB;
      }

      img {
        filter: grayscale(0.5);
      }
    }

    &:hover:not(.disabled) {
      border-color: #1FDCB3;
      cursor: pointer;
    }
  }

  .content {
    position: absolute;
    top: 54px;
    background-color: $white;
    z-index: 999;
    border-radius: 12px;

    .rowButton {
      color: $blue_grey;
      margin: 6px;
      padding: 6px;
      border-radius: 8px;

      &:hover {
        cursor: pointer;
        background-color: $light_pink;
      }

      &.disabled {
        cursor: default;
        background-color: $button_disabled;
        color: $content_disabled;
      }
    }

    .loadingContainer {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background-color: #80808038;
    }
  }
}