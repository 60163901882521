@import '../../../constants/Colors.module.scss';

.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: $pink_gradient;
  height: 2.5rem;
  overflow: hidden;
  border-radius: 12px;
  padding: 0 1.5rem;
  color: $white;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 1s;
  cursor: pointer;
  text-transform: uppercase;

  &:disabled {
    background: $button_disabled;
    color: $content_disabled;
  }

  &.cancel {
    background: unset;
    border: unset;
    color: $light_blue;

    &:hover {
      background: unset;
      box-shadow: unset;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
  }
}