@import '../../../constants/Colors.module.scss';

.container {
    display: flex;
    align-items: center;
    height: 100vh;
    background-color: $extra_light_blue;
    background-image: url('../../../assets/images/background/login.png');
    background-repeat: no-repeat;
    background-position: bottom right;
}