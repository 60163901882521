/* FONTS SECTION */
@font-face {
  font-family: "Galano";
  font-weight: 100;
  src: url("./assets/fonts/Galano_Grotesque_Thin.otf");
}

@font-face {
  font-family: "Galano";
  font-weight: 200;
  src: url("./assets/fonts/Galano_Grotesque_ExtraLight.otf");
}

@font-face {
  font-family: "Galano";
  font-weight: 300;
  src: url("./assets/fonts/Galano_Grotesque_Light.otf");
}

@font-face {
  font-family: "Galano";
  font-weight: 400;
  src: url("./assets/fonts/Galano_Grotesque_Medium.otf");
}

@font-face {
  font-family: "Galano";
  font-weight: 500;
  src: url("./assets/fonts/Galano_Grotesque_SemiBold.otf");
}

@font-face {
  font-family: "Galano";
  font-weight: 700;
  src: url("./assets/fonts/Galano_Grotesque_Bold.otf");
}

@font-face {
  font-family: "Galano";
  font-weight: 800;
  src: url("./assets/fonts/Galano_Grotesque_Heavy.otf");
}

@font-face {
  font-family: "Galano";
  font-weight: 900;
  src: url("./assets/fonts/Galano_Grotesque_Black.otf");
}
/* END FONTS SECTION */

/* Global style */
body {
  font-family: Galano;
  margin: 0;
  background: #f6f5fb;
  color: #3d366e;
  height: 100%;
}

button {
  font-family: Galano;
}

/* Timeseries section */
.chart-line {
  stroke-width: 1;
  fill: none;
  pointer-events: none;
}
