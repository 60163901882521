@import '../../../constants/Colors.module.scss';

.container {
  position: relative;
  padding-right: 1px;

  .input {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    background: $white;
    border: 1px solid $border_color;
    border-radius: 8px;
    width: calc(3*16px + 2*6px + 2*12px);
    cursor: pointer;

    .chevron {
      transform: rotate(-90deg);
      transition-duration: .3s;

      &.active {
        transform: rotate(90deg);
      }
    }
  }

  .list {
    position: absolute;
    top: 54px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 6px;
    background: $white;
    border: 1px solid $border_color;
    border-radius: 8px;
    padding: 12px;
    z-index: 99;
  }

  .dot {
    border-radius: 100%;
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding: unset;
    border: unset;
  }
}