@import '../../../../constants/Colors.module.scss';

.toolboxContainer {
  width: 100%;
  position: relative;
  padding-top: 32px;
  color: $blue_grey;

  button,
  select {
    background: $white;
    border: 1px solid $border_color;
    border-radius: 8px;
    cursor: pointer;
    padding: 8px 16px;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: $blue_grey;

    &:enabled:hover {
      border-color: $brand_pink;
    }

    &:disabled {
      filter: grayscale(1);
      cursor: unset;
    }

    &.active {
      border: 2px solid $brand_pink;
      margin: -1px;
    }
  }
}

.toolboxGroup {
  margin: 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid $extra_light_blue_background;

  &:last-of-type {
    border-bottom: unset;
  }

  .toolboxGroupTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: $dark_blue_two;
    margin: 0 0 24px;
  }

  .toolboxGroupContent>* {
    display: block;
    margin: 10px 0;
  }
}